import React, { useState, useEffect } from "react";
import { auth } from "../../firebase.js"
import { useNavigate } from "react-router-dom"

import "../../css/DocumentationHomeComponent.css"

function DocumentationHomeComponent() {

        const [loading, setLoading] = useState(true);
        const [uid, setUid] = useState("")
        const navigate = useNavigate();
        const [hasSpaces, setHasSpaces] = useState(false)

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setUid(user.uid)
                        } else {
                                setUid(null)
                        }
                })
                return () => { unsubscribe() }
        }, [])

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--bg-main-container', '#121d2d')
                        root.style.setProperty('--font-color', "#5B92E5")
                }
        }, []);

        useEffect(() => { if (uid) { fetchPageDetails() } }, [uid])

        const fetchPageDetails = async () => {
                try {
                        if (uid.length !== 0) {
                                const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/page-details/author/" + uid + "/", {
                                        method: "GET",
                                        headers: { "Content-Type": "application/json" }
                                });
                    
                                if (response.status === 200) {
                                        console.log("Successfully fetched data from page details");
                                        setHasSpaces(true)
                                        setLoading(false)
                                } else if (response.status === 404) {
                                        console.log("No page detail data found for this author.")
                                        setHasSpaces(false)
                                        setLoading(false)
                                } else {
                                        console.log("Failed to fetch page detail data.");
                                }
                        }

                } catch (error) {
                        console.error("Error getting data from page detail table:", error);
                }
        }

        const handleCreateClick = () => { navigate("/create") }
            
        if (loading) {
                return (
                        <div className="DocumentationHomeComponent__loading-animation-div">
                                <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                        </div>
                )
        }

        if (loading === false && hasSpaces === false) {
                return (
                        <div>
                                <p className="large">
                                        Looks like you haven't created any documentation yet! <strong>Click the button below to get started</strong>.
                                </p>
                                <button onClick={handleCreateClick} className="submit-button">Create</button>
                        </div>
                )
        }

        return(
                <div>
                        <p className="large">
                                <strong>To view/modify existing documentation</strong>, click on any of the spaces/pages on the left sidebar.
                        </p>
                        <p className="large" style={{ paddingTop: "50px" }}>
                                <strong>To create new documentation</strong>, click the green "Create" button on the bottom of the left sidebar.
                        </p>
                </div>
        )

}

export default DocumentationHomeComponent