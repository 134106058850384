import React, { useState, useEffect } from "react";

import "../../css/DocumentationMainPage.css"
import "../../css/Create.css"
import DocumentationHomeComponent from "./DocumentationHomeComponent";
import Sidebar from "../shared/Sidebar";

function DocumentationMainPage() {

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--font-color', '#5B92E5')
                        root.style.setProperty('--bg-color', '#121d2d')
                }
        }, []);

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">
                                <DocumentationHomeComponent />
                        </div>
                </div>
        )

}

export default DocumentationMainPage