import React, { useState, useEffect } from "react";
import { diffWords } from "diff";
import "../../css/ConflictResolver.css"

const ConflictResolver = ({ localContent, latestContent, onResolve }) => {

        const [mergedContent, setMergedContent] = useState(localContent);
        const [theme, setTheme] = useState(null)

        const diffs = diffWords(localContent, latestContent);

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                setTheme(theme)
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--btn-color', '#5B92E5')
                        root.style.setProperty('--bg-button', '#000000')
                        root.style.setProperty('--bg-conflict-resolver', "#090e16")
                        root.style.setProperty('--conflict-resolver-color', '#5B92E5')
                }
        }, []);

        const handleUseLocal = () => {
                onResolve(localContent);
        };

        const handleUseLatest = () => {
                onResolve(latestContent);
        };

        const handleManualMerge = () => {
                onResolve(mergedContent);
        };

        return (
                <div>
                <h3>Conflict Detected</h3>
                <p>A newer version of this document exists. Please resolve the conflict below:</p>
                
                <div className="ConflictResolver__container">
                        {diffs.map((part, index) => (
                        <span
                                key={index}
                                style={{
                                        color: part.added ? "green" : part.removed ? "red" : theme === "Dark Theme" ? "#5B92E5" : "#090e16",
                                        textDecoration: part.removed ? "line-through" : "none",
                                }}
                                dangerouslySetInnerHTML={{ __html: part.value }} // Render HTML safely
                        />
                        ))}
                </div>

                <h4>Resolve Conflict</h4>
                <button onClick={handleUseLocal} style={{ marginRight: "10px" }} className="ConflictResolver__button">Keep Local Changes</button>
                <button onClick={handleUseLatest} style={{ marginRight: "10px" }} className="ConflictResolver__button">Accept Latest Changes</button>
                <button onClick={handleManualMerge} className="ConflictResolver__button">Merge Manually</button>
                
                {mergedContent !== localContent && (
                        <textarea
                        value={mergedContent}
                        onChange={(e) => setMergedContent(e.target.value)}
                        style={{ marginTop: "20px", width: "100%", height: "200px" }}
                        />
                )}
                </div>
        );

};

export default ConflictResolver;
