import React from "react";
import { Routes, Route } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import HomePage from "./components/home_page/HomePage";
import Login from "./components/users/Login"
import Register from "./components/users/Register";
import Create from "./components/documentation/Create";
import DocumentationMainPage from "./components/documentation/DocumentationMainPage";
import Document from "./components/documentation/Document";
import EditDocument from "./components/documentation/EditDocument";
import Help from "./components/support/Help";
import SupportTicket from "./components/support/SupportTicket";
import Account from "./components/users/Account";
import Search from "./components/shared/Search";
import Theme from "./components/users/Theme";

const Main = () => {
        return (
                <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/register' element={<Register />} />
                        <Route path='/create' element={<Create />} />
                        <Route path='/account/:uid' element={<Account />} />
                        <Route path='/documentation' element={<DocumentationMainPage />} />
                        <Route path='/documentation/:type/:name/:uid' element={<Document />} />
                        <Route path='/documentation/:type/:name/:uid/edit' element={<EditDocument />} />
                        <Route path='/help' element={<Help />} />
                        <Route path="/help/support-ticket" element={<SupportTicket />} />
                        <Route path="/documentation/search" element={<Search />} />
                        <Route path="/themes" element={<Theme />} />
                </Routes>
        );
}

export default Main;