import React, { useState, useEffect } from "react";
import "../../css/DocumentationMainPage.css"
import Sidebar from "../shared/Sidebar.js";
import "../../css/Create.css"
import "../../css/Help.css"
import "../../css/Document.css"
import { useNavigate } from "react-router-dom"

function Help() {

        const [email, setEmail] = useState("")
        const [phoneNumber, setPhoneNumber] = useState("+1")
        const [subject, setSubject] = useState("")
        const [message, setMessage] = useState("")
        const [firstName, setFirstName] = useState("")
        const navigate = useNavigate();

        const handleSupportButtonClick = () => { navigate("/help/support-ticket") }

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--bg-color', '#121d2d')
                        root.style.setProperty('--font-color', '#5B92E5')
                        root.style.setProperty('--main-content-color', '#5B92E5')
                        root.style.setProperty('--text-area-border', '#5B92E5')
                        root.style.setProperty('--bg-text-area', '#1b2b44')
                }
        }, []);

        const handleSubmit = async () => {
                const data = {
                        email: email,
                        phoneNumber: phoneNumber,
                        subject: subject,
                        message: message,
                        firstName: firstName,
                }

                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/support/", {
                                method: "POST",
                                headers: {
                                        "Content-Type": "application/json"
                                },
                                body: JSON.stringify(data),
                        })

                        if (response.ok) {
                                console.log("Successfully submitted general contact form.")
                        } else {
                                console.log("Failed to send general contact form data.")
                        }
                } catch (error) {
                        console.error("Error in submitting general contact form data: ", error)
                }

                navigate("/")
        }

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">

                                <div className="Document__div">

                                        <p>We can't thank you enough for giving our product a shot; we hope we can meet all your documentation needs!</p>

                                        <p>
                                                <strong>If you have any questions, comments, and/or concerns</strong>, please feel free to use the form below to send us 
                                                an email. We're a very small team, but we'll try our best to get back to you within 2 business days.
                                        </p>

                                        <p>
                                                <strong>If you would like to submit a support ticket</strong>, please click the button below.
                                        </p>

                                        <button onClick={handleSupportButtonClick} className="Help__submit-button">Submit Support Ticket</button>

                                        <div className="Create__form-group-helper-text">
                                                <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                <p className="Create__helper-text">
                                                        If you're encountering any technical issues while trying to use our app, we recommend submitting a ticket; rather than sending an 
                                                        email via the form below. We try our best to log all errors, so there is a chance we're already working on an error you've encountered, but 
                                                        we still highly recommend submitting a detailed ticket.
                                                </p>
                                        </div>

                                        <h2 style={{ marginTop: "50px" }}>General Contact Form</h2>

                                        <div className="Create__form-group">
                                                <label>First Name: </label>
                                                <input
                                                        type="text"
                                                        id="firstName"
                                                        name="email"
                                                        className="Create__input-short-field"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        maxLength={100}
                                                        required
                                                />
                                        </div>

                                        <div className="Create__form-group">
                                                <label>Email: </label>
                                                <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="Create__input-short-field"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                />
                                        </div>

                                        <div className="Create__form-group">
                                                <label>Phone Number: </label>
                                                <input
                                                        type="tel"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        className="Create__input-short-field"
                                                        onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (/^\+?[0-9\s]*$/.test(value)) {
                                                                        setPhoneNumber(value);
                                                                }
                                                        }}
                                                        placeholder="+1 (123) 456-7890"
                                                        pattern="^\+\d{1,4}\s?\(?\d{1,4}\)?\s?\d{1,9}(\s?\d{1,9})?$"
                                                        title="Phone number must start with a '+' followed by the country code and numbers."
                                                />
                                        </div>
                                        <div className="Create__form-group-helper-text">
                                                <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                <p className="Create__helper-text">
                                                        We will never call or text you without emailing you first. This field is optional, and it is 
                                                        only to set up a phone call if needed.
                                                </p>
                                        </div>

                                        <div className="Create__form-group">
                                                <label>Subject: </label>
                                                <input
                                                        type="text"
                                                        id="subject"
                                                        name="subject"
                                                        className="Create__input-full-field"
                                                        value={subject}
                                                        onChange={(e) => setSubject(e.target.value)}
                                                        maxLength={150}
                                                        required
                                                />
                                        </div>

                                        <div className="Create__form-group-text-area">
                                                <label>Message: </label>
                                                <textarea
                                                        className="Create__input-text-area"
                                                        rows="10"
                                                        id="message"
                                                        name="message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        required
                                                ></textarea>
                                        </div>

                                        <button onClick={handleSubmit} className="submit-button Help__shorter-margin-top">Submit</button>

                                </div>

                        </div>

                </div>
        )

}

export default Help