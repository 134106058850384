import "../../css/Theme.css"

function Theme() {

        return (
                <div>

                        Hello

                </div>
        )

}

export default Theme