import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
        if (!stripePromise) {
                stripePromise = loadStripe('pk_live_51QYGwO2NcK2IZo0y0rtdLozrfhmalNYrAB6aOCCmKM71fegUpC5QbtaeqSQ6s2enqlS2kOyP2oyDWZaQabzXkBsA00EEajvxub');
        }
        return stripePromise;
};

export default getStripe;