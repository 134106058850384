import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import Quill from "quill";
import { useParams, useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import { diffWords } from 'diff';
import ImageResize from 'quill-image-resize-module-react';
import "../../css/Create.css"
import { auth } from "../../firebase.js";
import Sidebar from "../shared/Sidebar.js";
import "../../css/EditDocument.css"
import Select from 'react-select';
import Tesseract from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist';
import ConflictResolver from './ConflictResolver.js';
import "../../css/DocumentationHomeComponent.css"

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

Quill.register('modules/imageResize', ImageResize);
const Inline = Quill.import('blots/inline');

class InlineCode extends Inline {
        static create() {
                let node = super.create();
                node.setAttribute('class', 'inline-code');
                return node;
        }
    
        static formats(node) {
                return true;
        }
}
    
InlineCode.blotName = 'inline-code';
InlineCode.tagName = 'code';
    
Quill.register(InlineCode);

function EditDocument() {

        const { type, name, uid } = useParams()
        const [selectedType, setSelectedType] = useState("")
        const [newName, setNewName] = useState("")
        const [description, setDescription] = useState("")
        const [allDocumentation, setAllDocumentation] = useState([])
        const [selectedParent, setSelectedParent] = useState("")
        const [isPublic, setIsPublic] = useState(false)
        const [completed, setCompleted] = useState(false)
        const [emails, setEmails] = useState([]); 
        const [currentEmail, setCurrentEmail] = useState("")
        const [content, setContent] = useState("")
        const [loading, setLoading] = useState(true)
        const [hasPermission, setHasPermission] = useState(true)
        const [userUid, setUserUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const [documentVersion, setDocumentVersion] = useState(null);
        const [showConflictResolver, setShowConflictResolver] = useState(false);
        const [latestContent, setLatestContent] = useState("");
        const navigate = useNavigate()

        const [customLink, setCustomLink] = useState("")
        const [customLinkTitle, setCustomLinkTitle] = useState("")
        const [discordLink, setDiscordLink] = useState("")
        const [linkedInLink, setLinkedInLink] = useState("")
        const [youtubeLink, setYoutubeLink] = useState("")
        const [githubLink, setGithubLink] = useState("")
        const [twitterLink, setTwitterLink] = useState("")

        const maxLengths = {
                Space: 100,
                Page: 150,
        };

        const currentMaxLength = maxLengths[selectedType] || 100;

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--main-content-color', '#5B92E5')
                        root.style.setProperty('--bg-color', '#121d2d')
                        root.style.setProperty('--bg-slider-box', "#5B92E5")
                        root.style.setProperty('--bg-slider', '#3f66a0')
                        root.style.setProperty('--email-item-bg', '#000000')
                        root.style.setProperty('--bg-submit-button', '#000000')
                        root.style.setProperty("--placeholder-color", "#6b9ce7")
                        root.style.setProperty('--main-content-bg-color', '#121d2d')

                        const qlContainer = document.querySelector('.ql-container');
                        if (qlContainer) {
                                qlContainer.style.border = '2px solid #000000';
                                qlContainer.style.backgroundColor = "#1b2b44"
                        }
                        const qlToolbar = document.querySelector('.ql-toolbar')
                        if (qlToolbar) {
                                qlToolbar.style.border = '2px solid #000000'
                                qlToolbar.style.color = 'white'
                                qlToolbar.style.background = '#000000'
                        }
                        const qlFills = document.querySelectorAll('.ql-fill');
                        qlFills.forEach(fill => {
                                fill.style.fill = '#5b92e5';
                        });
                        const qlInLineCode = document.querySelector('.ql-inline-code')
                        if (qlInLineCode) {
                                qlInLineCode.style.color = "#5b92e5"
                        }
                        const qlStrokes = document.querySelectorAll('.ql-stroke');
                        qlStrokes.forEach(stroke => {
                                stroke.style.stroke = '#5b92e5';
                        });
                        const qlPickers = document.querySelectorAll('.ql-picker');
                        qlPickers.forEach(picker => {
                                picker.style.color = '#5b92e5';
                        });
                        const qlPickerOptions = document.querySelectorAll('.ql-picker-options');
                        qlPickerOptions.forEach(option => {
                                option.style.backgroundColor = '#000000';
                                option.style.color = '#5b92e5';
                        });
                        const qlPickerLabels = document.querySelectorAll('.ql-picker-label');
                        qlPickerLabels.forEach(label => {
                                const svg = label.querySelector('svg');
                                if (svg) {
                                        svg.style.fill = '#5b92e5';
                                }
                        });
                }
        }, []);

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setUserUid(user.uid)
                                setUserEmail(user.email)
                        } else {
                                setUserUid(null)
                                setUserEmail(null)
                                setHasPermission(false)
                                setLoading(false)
                        }
                })
        })

        useEffect(() => { 
                if (uid && userUid && userEmail) { 
                        setSelectedType(String(type).charAt(0).toUpperCase() + String(type).slice(1))
                        setNewName(name)
                        fetchDocumentContent() 
                        fetchPageDetailContent()
                        fetchPermissionsForThisDocument()
                } 
        }, [type, name, uid, userUid, userEmail])

        useEffect(() => {
                if (type === "page" || type ==="space") {
                        populateDocumentation();
                }
        }, [selectedType]);

        const handleAddEmail = () => {
                if (currentEmail && !emails.some(item => item.email === currentEmail)) {
                        setEmails([...emails, { email: currentEmail, permission: "Can Edit" }]);
                        setCurrentEmail("");
                } else {
                        alert("This email has already been added.");
                }
        };

        const handleRemoveEmail = (index) => {
                const updatedEmails = [...emails];
                updatedEmails.splice(index, 1);
                setEmails(updatedEmails);
        };
            

        const handlePermissionChange = (index, newPermission) => {
                const updatedEmails = [...emails];
                updatedEmails[index].permission = newPermission;
                setEmails(updatedEmails); 
        };

        const populateDocumentation = async () => {
                try {
                        const [spaceResponse, pageResponse] = await Promise.all([
                                fetch("https://bluebirddocumentationadmin.pythonanywhere.com/spaces/author/" + uid + "/"),
                                fetch("https://bluebirddocumentationadmin.pythonanywhere.com/pages/author/" + uid + "/")
                        ]);
            
                        const spaceData = spaceResponse.status === 200 ? await spaceResponse.json() : { spaces: [] };
                        const pageData = pageResponse.status === 200 || pageResponse.status === 404 ? await pageResponse.json() : [];
                        const spaceOptions = Array.isArray(spaceData.spaces) ? spaceData.spaces.map(space => ({ label: space.name, value: space.id })) : [];
                        const pageOptions = Array.isArray(pageData.pages) ? pageData.pages.map(page => ({ label: page.page_name, value: page.id })) : [];
                
                        setAllDocumentation([...spaceOptions, ...pageOptions]);
                } catch (error) {
                        console.error("Error fetching data:", error);
                }
        };

        const handleSelectedType = (e) => { setSelectedType(e.target.value) }
        const handleCompletedToggle = (e) => { setCompleted(e.target.checked) }
        const handleToggle = (e) => { setIsPublic(e.target.checked) }

        const fetchPermissionsForThisDocument = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/permissions/${type}/${name}/`)
                        const data = await response.json()
                        const existingPermissions = data.map(permission => {
                                let permissionLevel;
                                if (permission.can_edit) {
                                        permissionLevel = "Can Edit";
                                } else if (permission.can_view) {
                                        permissionLevel = "Can View";
                                } else {
                                        permissionLevel = ""; 
                                }
                    
                                return {
                                        email: permission.user_email,
                                        permission: permissionLevel
                                };
                        });
                    
                        setEmails(existingPermissions);
                } catch (error) {
                        console.error("Error fetching permission data: ", error)
                }
        }

        const fetchDocumentContent = async () => {
                if (!userUid || !userEmail) {
                        setLoading(false)
                        return
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/${type}/${name}/${uid}/`, {
                                method: "GET",
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                }
                        })

                        setLoading(false)

                        if (response.status === 403) {
                                setHasPermission(false)
                                return
                        }

                        const data = await response.json()
                        setDescription(data.description)
                        setCompleted(data.completed)
                        setContent(data.content)
                        setDocumentVersion(data.version);
                        setYoutubeLink(data.youtube_channel)
                        setCustomLink(data.custom_domain)
                        setCustomLinkTitle(data.custom_domain_title)
                        setDiscordLink(data.discord_server)
                        setLinkedInLink(data.linkedin_link)
                        setGithubLink(data.github_link)
                        setTwitterLink(data.twitter_link)
                } catch (error) {
                        setLoading(false)
                        console.error("Error fetching document data: ", error)
                }
        }

        const fetchPageDetailContent = async () => {
                if (!uid) return
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/page-details/${type}/${name}/${uid}/`)
                        const data = await response.json()
                        setSelectedParent(data.parent_page)
                        setIsPublic(data.is_public)
                } catch (error) {
                        console.error("Error fetching page detail content: ", error)
                }
        }

        const modules = {
                toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }],   
                        [{ 'size': [] }],    
                        ['bold', 'italic', 'underline', 'strike'],   
                        [{ 'color': [] }, { 'background': [] }],    
                        [{ 'script': 'sub' }, { 'script': 'super' }],     
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],   
                        [{ 'indent': '-1' }, { 'indent': '+1' }],     
                        [{ 'align': [] }],   
                        ['blockquote', 'code-block'],   
                        ['link', 'image', 'video'],
                        ['inline-code'],
                        ['formula'], 
                        ['clean']  
                ],
                imageResize: {
                        parchment: Quill.import('parchment'),
                        modules: ['Resize', 'DisplaySize']
                }
        };

        const handleSubmit = async () => {

                updatePermissionTableEntries()

                const data = {
                        type: selectedType,
                        isPublic: isPublic,
                        emails: emails,
                        content: content,
                        newName: newName,
                        author: uid,
                        completed: completed,
                        description: description,
                        parentPage: selectedParent,
                        version: documentVersion,
                        customLink: customLink,
                        customLinkTitle: customLinkTitle,
                        twitterLink: twitterLink,
                        discordLink: discordLink,
                        linkedInLink: linkedInLink,
                        githubLink: githubLink,
                        youtubeLink: youtubeLink,
                }

                console.log(data)

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/edit/${type}/${name}/`, {
                                method: "PATCH",
                                headers: {
                                        "Content-Type": "application/json",
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                },
                                body: JSON.stringify(data),
                        })

                        if (response.ok) {
                                console.log("Successfully submitted edits!")
                                navigate(`/documentation/${selectedType.toLowerCase()}/${newName}/${uid}/`)
                        } else if (response.status === 409) {
                                // Fetch the latest document content
                                const latestResponse = await fetch(
                                        `https://bluebirddocumentationadmin.pythonanywhere.com/documentation/${type}/${name}/${uid}/`,
                                        {
                                                method: "GET",
                                                headers: {
                                                        "requesting-user-uid": userUid,
                                                        "requesting-user-email": userEmail,
                                                },
                                        }
                                );
                        
                                if (latestResponse.ok) {
                                        alert("There appears to be a conflict between your current changes, and changes made by another user while editing this document. Please resolve them and submit again.")
                                        const latestData = await latestResponse.json();
                                        setLatestContent(latestData.content);
                                        setDocumentVersion(latestData.version);
                                        setShowConflictResolver(true);
                                } else {
                                        console.error("Failed to fetch the latest version of the document.");
                                }
                        } else {
                                console.log("Failed to submit edits.")
                        }
                } catch (error) {
                        console.error("Error submitting edits: ", error)
                }
        }

        const updatePermissionTableEntries = async () => {
                const data = {
                        name: newName,
                        documentationType: selectedType,
                        userEmail: emails,
                        isPublic: isPublic,
                        documentUid: uid,
                        documentUrl: `documentation/${selectedType.toLowerCase()}/${newName}/${uid}/`,
                        description: description
                }

                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/permissions/edit/", {
                                method: "POST",
                                headers: {
                                        "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                        })

                        if (response.ok) {
                                console.log("Successfully edited permissions.")
                        } else {
                                console.log("Failed to edit permissions.")
                        }
                } catch (error) {
                        console.error("Error submitting form: ", error)
                }
        }

        const formatExtractedText = (text) => {
                // Detect paragraphs by newlines or specific patterns
                const paragraphs = text.split(/\n(?=[A-Z])/).map((paragraph) => {
                    // Replace single newlines with <br /> for line breaks inside paragraphs
                    return `<p>${paragraph.trim().replace(/\n/g, '<br />')}</p>`;
                });
            
                return paragraphs.join('');
            };

            const handleFileUpload = async (event) => {
                const file = event.target.files[0];
            
                if (!file) return;
            
                try {
                    let rawText = '';
                    if (file.type === 'application/pdf') {
                        rawText = await extractTextFromPDF(file);
                    } else if (file.type.startsWith('image/')) {
                        rawText = await extractTextFromImage(file);
                    } else {
                        alert('Unsupported file type. Please upload an image or PDF.');
                        return;
                    }
            
                    // Format the extracted text
                    const formattedText = formatExtractedText(rawText);
            
                    // Set the formatted content into the editor
                    setContent((prevContent) => `${prevContent}${formattedText}`);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            };
              
              const extractTextFromImage = async (file) => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                  reader.onload = async () => {
                    try {
                      const { data: { text } } = await Tesseract.recognize(reader.result, 'eng', {
                        logger: (info) => console.log(info), // Monitor progress
                      });
                      resolve(text);
                    } catch (error) {
                      reject(error);
                    }
                  };
                  reader.onerror = (error) => reject(error);
                  reader.readAsDataURL(file);
                });
              };
              
              const extractTextFromPDF = async (file) => {
                const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
                let fullText = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                  const page = await pdf.getPage(i);
                  const textContent = await page.getTextContent();
                  const pageText = textContent.items.map((item) => item.str).join(' ');
                  fullText += `\nPage ${i}:\n${pageText}`;
                }
                return fullText;
              };

        const handleHomeButtonClick = () => { navigate("/") }
        const handleLoginButtonClick = () => { navigate("/login") }
        const handleRegisterButtonClick = () => { navigate("/register") }

        if (loading) {
                <div className="DocumentationHomeComponent__loading-animation-div">
                        <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                </div>
        }

        if (loading === false && hasPermission === false) {
                return (
                        <div className="DocumentationMainPage__div">
                                {/* <Sidebar /> */}
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks like you don't have permission to edit this document.</p>
                                        <p className="medium">
                                                <strong>You might just need to sign in</strong>. If this is your document, make sure you're signed in. If 
                                                this issue persists, this could be a bug on our end. We are very sorry for the inconvenience; please let us know 
                                                this has happened.
                                        </p>
                                        <p className="medium">
                                                <strong>If this is not your document</strong>, check with the original author of the document to see if you have correct 
                                                permissions.
                                        </p>
                                        <button onClick={handleHomeButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Home</button>
                                        <button onClick={handleLoginButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Login</button>
                                        <button onClick={handleRegisterButtonClick} className="ConflictResolver__button">Register</button>
                                </div>
                        </div>
                )
        }

        return (
                <div className="Create__div">

                        <Sidebar />

                        <div className="Create__main-content">

                                <h2>Edit Space/Page</h2>

                                <div className="Create__form-group">
                                        {type === "page" && (
                                                <>
                                                        <label>Edit Document Type: </label>
                                                        <select
                                                                className="form-select Create__input"
                                                                aria-label="Default select example"
                                                                name="selected_type"
                                                                value={selectedType}
                                                                onChange={handleSelectedType}
                                                        >
                                                                <option value="Page">Page</option>
                                                                <option value="Space">Space</option>
                                                        </select>
                                                </>
                                        )}
                                        {type === "space" && (
                                                <>
                                                        <label>Edit Document Type: </label>
                                                        <select
                                                                className="form-select Create__input"
                                                                aria-label="Default select example"
                                                                name="selected_type"
                                                                value={selectedType}
                                                                onChange={handleSelectedType}
                                                        >
                                                                <option value="Space">Space</option>
                                                                <option value="Page">Page</option>
                                                        </select>
                                                </>
                                        )}
                                </div>

                                {selectedType === "Page" && (
                                        <div className="Create__form-group">
                                                <label style={{ paddingTop: "3px" }}>Select a parent space or page: </label>
                                                <Select
                                                        options={allDocumentation.filter(option => option.label !== newName)}
                                                        value={allDocumentation.find(option => option.value === selectedParent)} 
                                                        onChange={(option) => setSelectedParent(option ? option.label : "")}
                                                        placeholder={selectedParent}
                                                        isSearchable={true}
                                                />
                                        </div>
                                )}

                                <div className="Create__form-group">
                                        <label>Edit Name: </label>
                                        <input
                                                type="text"
                                                id="name"
                                                className="Create__input-short-field"
                                                value={newName}
                                                onChange={(e) => setNewName(e.target.value)}
                                                maxLength={currentMaxLength}
                                                placeholder={`Max ${currentMaxLength} characters`}
                                        />
                                </div>

                                <div className="Create__form-group">
                                        <label>Edit Description: </label>
                                        <input
                                                type="text"
                                                id="description"
                                                className="Create__input-full-field"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                        />
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                This description is what invited users will see about 
                                                this space/page.
                                        </p>
                                </div>

                                <div className="Create__form-group">
                                        <label>Edit Public Status: </label>
                                        <label className="Create__switch">
                                                <input 
                                                        type="checkbox"
                                                        checked={isPublic}
                                                        onChange={handleToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Public content is viewable by anyone that has this document's URL. 
                                        </p>
                                </div>

                                <div className="Create__form-group">
                                        <label>Publish this page upon finishing changes?</label>
                                        <label className="Create__switch">
                                                <input
                                                        type="checkbox"
                                                        checked={completed}
                                                        onChange={handleCompletedToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Whether the document is public or not, this document will not be <strong>viewable</strong> by anyone; including 
                                                added collaborators that have "Can View" permission. Collaborators that have "Can Edit" permission can edit this document.
                                        </p>
                                </div>

                                <div className="Create__form-group" style={{ flexDirection: "column", marginBottom: "60px" }}>
                                        <label>Modify/Add Collaborators</label>
                                        {emails.map((item, index) => (
                                                <div key={index} className="Create__email-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <span>{item.email}</span>
                                                        <select
                                                                value={item.permission}
                                                                onChange={(e) => handlePermissionChange(index, e.target.value)}
                                                                style={{ marginLeft: '10px', padding: '5px' }}
                                                        >
                                                                <option value="Can Edit" disabled={uid != userUid}>Can Edit</option>
                                                                <option value="Can View" disabled={uid != userUid}>Can View</option>
                                                        </select>
                                                        {uid === userUid ? (
                                                                <button onClick={() => handleRemoveEmail(index)} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
                                                                        <i className="fas fa-trash-alt" title="Remove Email" style={{ color: "white" }}></i>
                                                                </button>
                                                        ) : (
                                                                <></>
                                                        )}
                                                </div>
                                        ))}
                                        {uid === userUid ? (
                                                <>
                                                        <div className="Create__email-input-container">
                                                                <input
                                                                        type="email"
                                                                        className="Create__email-input"
                                                                        placeholder="Enter E-mail Address"
                                                                        value={currentEmail}
                                                                        onChange={(e) => setCurrentEmail(e.target.value)}
                                                                        onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
                                                                />
                                                                <button onClick={handleAddEmail} className="Create__add-email-btn">
                                                                        <i className="fas fa-plus"></i>
                                                                </button>
                                                        </div>
                                                        <div className="Create__form-group-helper-text">
                                                                <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                                <p className="Create__helper-text">
                                                                        As of this current version, any added permissions will <strong>not</strong> automatically be added to any future child documents that are created.
                                                                </p>
                                                        </div>
                                                </>
                                        ) : (
                                                <div className="Create__form-group-helper-text">
                                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                        <p className="Create__helper-text">
                                                                As of this current release, only original authors can edit permissions.
                                                        </p>
                                                </div>
                                        )}
                                </div>

                                <div className="Create__form-group">
                                        <label>Upload Notes: </label>
                                        <input
                                                type="file"
                                                className="Create__file-upload"
                                                accept=".jpeg,.jpg,.png,.pdf"
                                                onChange={handleFileUpload}
                                                multiple={false}
                                        />
                                </div>
                                <div className="Create__form-group-helper-text" style={{ marginBottom: "60px" }}>
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                You can upload any typed notes or handwritten notes, and we'll do our best to try to extract text from it. Anything that is extracted will be placed in the text editor below. This feature is cetainly very early in development; we're working to improve this feature throughout the releases.
                                        </p>
                                </div>

                                <input
                                        type="text"
                                        placeholder="Custom Link Title"
                                        value={customLinkTitle}
                                        onChange={(e) => setCustomLinkTitle(e.target.value)}
                                        className="Account__input-custom-title"
                                />
                                <input
                                        id="customLinkInput"
                                        type="text"
                                        placeholder="Custom Link Domain"
                                        value={customLink}
                                        onChange={(e) => setCustomLink(e.target.value)}
                                        className="Account__edit-input"
                                />

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-discord Account__icons"></i>
                                        <input
                                                type="text"
                                                value={discordLink}
                                                placeholder="Discord Server Link"
                                                onChange={(e) => setDiscordLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-x-twitter Account__icons"></i>
                                        <input
                                                type="text"
                                                value={twitterLink}
                                                placeholder="X Profile Link"
                                                onChange={(e) => setTwitterLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-linkedin Account__icons"></i>
                                        <input
                                                type="text"
                                                value={linkedInLink}
                                                placeholder="LinkedIn Profile Link"
                                                onChange={(e) => setLinkedInLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-youtube Account__icons"></i>
                                        <input
                                                type="text"
                                                value={youtubeLink}
                                                placeholder="YouTube Channel Link"
                                                onChange={(e) => setYoutubeLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-github Account__icons"></i>
                                        <input
                                                type="text"
                                                value={githubLink}
                                                placeholder="GitHub Profile Link"
                                                onChange={(e) => setGithubLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Create__form-group-helper-text" style={{ marginBottom: "60px" }}>
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                These are links that users will see when visiting this document. The links are automatically pulled from what's saved in your account settings. Feel free to edit any of the links if you don't want to use what's pulled from 
                                                your account settings. <strong>If you don't want your saved link(s) to show for this particular document, </strong>just remove the contents of that input; until you see the placeholder text again.
                                        </p>
                                </div>

                                <div className="EditDocument__quill-container">
                                        {showConflictResolver ? (
                                                <ConflictResolver
                                                        localContent={content}
                                                        latestContent={latestContent}
                                                        onResolve={(resolvedContent) => {
                                                                setContent(resolvedContent);
                                                                setShowConflictResolver(false);
                                                        }}
                                                />
                                        ) : (
                                                <ReactQuill
                                                        value={content}
                                                        onChange={setContent}
                                                        modules={modules}
                                                        formats={[
                                                                'header', 'list', 'bold', 'italic', 'underline', 'code-block', 'blockquote',
                                                                'link', 'image', 'video', 'strike', 'color', 'background', 'indent',
                                                                'size', 'align', 'script', 'direction', 'code', 'inline-code', 'formula',
                                                        ]}
                                                        style={{ height: "70vh" }}
                                                />
                                        )}
                                </div>

                                <button onClick={handleSubmit} className="submit-button">Submit</button>

                        </div>

                </div>
        )

}

export default EditDocument