import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "firebase/auth"
import { auth } from "../../firebase.js"
import { useNavigate } from "react-router-dom"

import Header from "../shared/Header"

import "../../css/HomePage.css"

function HomePage() {

        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(true); 
        const [error, setError] = useState(null); 
        const navigate = useNavigate();
        const [loggedIn, setLoggedIn ] = useState(false)

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--bg-color-create-div', '#3f66a0'); 
                        root.style.setProperty('--bg-color-icon', '#090e16')
                        root.style.setProperty('--text-color', "#5b92e5")
                        root.style.setProperty('--text-color-alternate', "#3f66a0")
                }
        }, []);

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setLoggedIn(true)
                        } else {
                                setLoggedIn(false)
                        }
                })

                return () => { unsubscribe() }
        }, [])

        const handleDivRedirect = () => {
                if (loggedIn) {
                        navigate("/create");
                } else {
                        navigate("/register")
                }
        }

        useEffect(() => {
                fetch("/api/your-endpoint") // Change Me
                        .then((response) => {
                                if (!response.ok) {
                                        throw new Error("Network response was not ok");
                                }
                        return response.json();
                        })
                        .then((data) => {
                                if (data && data.length > 0) {
                                        setData(data); 
                                } else {
                                        setData(null);
                                }
                        })
                        .catch((err) => {
                                setError(err.message);
                        })
                        .finally(() => {
                                setLoading(false);
                        });
        }, []);

        if (loading) {
                return (
                        <div className="loading-animation-div">
                                <i className="fas fa-spinner fa-spin loading-animation-icon"></i>
                        </div>
                )
        }

        return (
                <div className="container-fluid" style={{ padding: "0", overflow: "hidden" }}>
                        <div style={{ background: "#090e16" }}>
                                <Header />
                        </div>
                        <div className="notification"></div>
                        <div className="row HomePage__main-container">
                                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                        <h1 className="large HomePage__text"><span className="HomePage__text-alternate">Bluebird</span> Documentation</h1>
                                        <p className="large HomePage__text">Documentation made simple, free, and easy on the eyes; as it should be.</p>
                                        <div className="HomePage__create-container" onClick={handleDivRedirect}>
                                                {loggedIn ? (
                                                        <>
                                                                <h3 className="light" style={{ color: "#090e16" }}>Create</h3>
                                                                <i className="fas fa-plus HomePage__plus-sign"></i>
                                                        </>
                                                ) : (
                                                        <>
                                                                <h3 className="light" style={{ color: "#090e16" }}>Register</h3>
                                                                <i class="fa-solid fa-user-plus HomePage__register-icon"></i>
                                                        </>
                                                )}
                                        </div>
                                </div>
                        </div>
                </div>
        )
}

export default HomePage